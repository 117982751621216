
import {
    Component,
    Vue
} from 'vue-property-decorator'
import {
    apiUserList
} from '@/api/user'
import {
    RequestPaging
} from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '../../components/ls-dialog.vue'
@Component({
    components: {
        LsPagination,
        ExportData,
        LsDialog,
        DatePicker
    }
})
export default class UserManagement extends Vue {
    /** S Data **/
    form = {
        keyword: '', // 用户信息
        create_start_time: '', // 注册开始时间
        create_end_time: '', // 注册结算时间
        disable: '' //会员状态
    }
    // 日期选择器数据
    timeForm = []
    // 分页查询
    pager: RequestPaging = new RequestPaging()
    /** E Data **/

    /** S Methods **/
    apiUserList = apiUserList // 传递给导出组件的api

    // 查询按钮
    query() {
        this.pager.page = 1
        this.getUserList()
    }

    //获取用户列表数据
    getUserList() {
        this.pager.request({
            callback: apiUserList,
            params: {
                ...this.form
            }
        })
    }

    // 重置按钮
    onReset() {
        this.pager.page = 1
        this.form = {
            keyword: '', // 用户信息
            create_start_time: '', // 注册开始时间（传时间戳）
            create_end_time: '', // 注册结算时间（传时间戳）
            disable: '' //会员状态
        }
        this.timeForm = []
        this.getUserList()
    }
    // 用户详情
    DetailsClick(item: any) {
        this.$router.push({
            path: '/user/user_details',
            query: {
                id: item.id
            }
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getUserList()
    }
    /** E Life Cycle **/
}
